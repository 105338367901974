<template>
  <div>
    <v-row>
      <v-col cols="6">
        <app-text-search-field @input="select()" v-model="searchParams.text" />
      </v-col>
      <v-col cols="1">
        <v-btn @click="select()" large outlined color="primary">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ProductSearch
          clearable
          :showFinalCustomer="true"
          @input="handleRelationshipInput($event, 'product_id')"
        />

        <!-- <app-text-field
          v-model="searchParams.product_name"
          label="Produto"
          @keyup.enter.native="select()"
        /> -->
      </v-col>
      <v-col>
        <CustomerSearch
          clearable
          :showFinalCustomer="true"
          @input="handleRelationshipInput($event, 'customer_id')"
        />
      </v-col>
      <v-col v-if="!$acl.isSalesman()">
        <SalesmanSearch
          :hidePosition="true"
          clearable
          @input="handleRelationshipInput($event, 'salesman_id')"
        />
      </v-col>

      <v-col>
        <app-select
          v-model="searchParams.status"
          :items="statusOptions"
          label="status"
          multiple
          clearable
        />
      </v-col>
    </v-row>
    <v-row class="align-end">
      <v-col>
        <app-date-field
          label="Data Inicial"
          v-model="searchParams.start_date"
          id="start_date"
        />
      </v-col>
      <v-col>
        <app-date-field
          label="Data Final"
          v-model="searchParams.end_date"
          id="end_date"
        />
      </v-col>

      <v-col>
        <app-select
          v-model="searchParams.delivery_status"
          :items="deliveryStatusData"
          label="Entrega"
          @input="select()"
        />
      </v-col>
      <v-col>
        <OperationSelect
          v-model="searchParams.operation_id"
          @input="select()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <BrandsSearch @input="handleRelationshipInput($event, 'brand_id')" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-pagination @click="paginate($event)" :data="products" />
        <v-data-table
          :headers="headers"
          :items="products.data"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          dense
          @click:row="handleSaleView($event.sale.id)"
        >
          <template v-slot:[`item.sale`]="{ item }">
            {{ item.sale.number }}
          </template>
          <template v-slot:[`item.product`]="{ item }">
            {{ item.product.code }} - {{ item.product.name }}
          </template>
          <template v-slot:[`item.brand`]="{ item }">
            <span v-if="item.product.brand_id">
              {{ item.product.brand.name }}
            </span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ $format.dateBr(item.sale.created_at) }}
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            {{ $format.decimal(item.quantity) }}
            <span v-if="item.product_variant.unity_id">
              {{ item.product_variant.unity.symbol }}
            </span>
          </template>
          <template v-slot:[`item.reserved_stock`]="{ item }">
            {{ $format.decimal(item.reserved_stock) }}
            <span v-if="item.product_variant.unity_id">
              {{ item.product_variant.unity.symbol }}
            </span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            {{ $format.moneyBr(item.price) }}
          </template>
          <template v-slot:[`item.net_total`]="{ item }">
            {{ $format.moneyBr(item.net_total) }}
          </template>
          <template v-slot:body.append>
            <tr>
              <td colspan="5" />

              <td class="text-center">
                <strong>Qt Total: </strong>
                {{ quantity_total }}
              </td>
              <td class="text-center">
                <strong>Rs Total: </strong>
                {{ reserved_stock_total }}
              </td>
              <td class="text-center">
                <strong>Pr. Total: </strong> {{ $format.moneyBr(total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SaleView ref="SaleView" @store="select()" />
  </div>
</template>

<script>
import SaleView from "@/components/sales/sections/SaleView.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";
import OperationSelect from "@/components/operation/ui/OperationSelect.vue";
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";

export default {
  components: {
    SaleView,
    SalesmanSearch,
    CustomerSearch,
    OperationSelect,
    ProductSearch,
    BrandsSearch,
  },
  data() {
    return {
      products: [],
      total: 0,
      quantity_total: 0,
      reserved_stock_total: 0,
      showFilter: false,
      searchParams: {
        text: null,
        status: null,
      },
      headers: [
        { text: "Venda", value: "sale", align: "center" },
        { text: "Produto", value: "product", align: "center" },
        { text: "Marca", value: "brand", align: "center" },
        { text: "Emissão", value: "created_at", align: "center" },
        { text: "Preço", value: "price", align: "center" },
        { text: "Qtde", value: "quantity", align: "center" },
        {
          text: "Qt Reservado",
          value: "reserved_stock",
          align: "center",
        },
        { text: "Total", value: "net_total", align: "center" },
      ],

      statusOptions: [
        { value: null, text: "Todos" },
        { value: "draft", text: "Rascunho" },
        { value: "proposal", text: "Orçamento" },
        { value: "waiting_approval", text: "Aguardando aprovação" },
        { value: "order", text: "Pedido" },
        { value: "sale", text: "Venda" },
        { value: "canceled", text: "Cancelado" },
      ],

      deliveryStatusData: [
        { value: "", text: "Todos" },
        { value: "not_delivered", text: "Sem Entrega" },
        { value: "partial", text: "Parcial" },
        { value: "delivered", text: "Entregue" },
      ],
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.userData.user;
    },
  },

  created() {
    if (this.$acl.isSalesman()) {
      this.setSalesman(this.user);
    }
  },

  methods: {
    select() {
      this.$loading.start();
      this.$http
        .index("sale/sale-product-report", this.searchParams)
        .then((response) => {
          this.products = response.products;
          this.total = response.total;
          this.quantity_total = response.quantity_total;
          this.reserved_stock_total = response.reserved_stock_total;
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },

    search(status) {
      this.searchParams.status = status;
    },

    setSalesman(salesman) {
      this.searchParams.salesman_id = salesman ? salesman.id : null;
    },

    setCustomer(customer) {
      this.searchParams.customer_id = customer ? customer.id : null;
    },

    setProduct(product) {
      this.searchParams.product_id = product ? product.id : null;
    },

    handleRelationshipInput(relationship, field) {
      if (relationship) {
        this.searchParams[field] = relationship.id;
      } else {
        this.searchParams[field] = null;
      }

      this.select();
    },

    handleSaleView(sale_id) {
      this.$refs.SaleView.open(sale_id);
    },
    paginate(page) {
      this.searchParams.page = page;
      this.select();
    },
  },
};
</script>

<style></style>
